<template>

  <div class="row" ref="recipeForm">

    <div class="col-xs-12 col-sm-12 col-md-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>

          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="The name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Name"
                            name="name"
                            fou
                            @keyup="generateMetaTitleURL"
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="url"
                  rules="required"
                  name="The url"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Url"
                            name="url"
                            fou
                            v-model="formData.url">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="meta_title"
                  rules="required"
                  name="The Meta Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Meta Title"
                            name="meta_title"
                            fou
                            v-model="formData.meta_title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="meta_description"
                  rules=""
                  name="The Meta Description"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Meta Description"
                            name="meta_description"
                            fou
                            v-model="formData.meta_description">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
                <div class="form-group">
                  <label>Feature</label>&nbsp;
                  <l-switch v-model="formData.is_featured">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
                <div class="form-group">
                  <label>Image
                    <span v-if="formData.is_featured">(image size 500 * 320)</span>
                    <span v-else> (image size 250 * 320)</span>
                  </label>
                  <image-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    v-model="formData.image"></image-uploader>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <label>Description</label>
                <editor

                  v-model="formData.description"
                  :api-key="editorKey"
                  :init='{
                    height: 300,
                    paste_data_images: true,
                    plugins: [
                    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                     "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media nonbreaking save table contextmenu directionality",
                    "emoticons template paste textcolor colorpicker textpattern"
                    ],
                    toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    toolbar2: "print preview media | forecolor backcolor emoticons",
                    image_advtab: true,
                  }'
                />
              </div>

            </div>
            <div class="row mt-2">
              <h4 class="card-title">Ingredients</h4>
              <div class="col-12">
                <template v-for="(ingredient,ingredientIndex) in formData.ingredients">
                  <div class="row">
                    <div class="col-5">
                      <fg-input type="text"
                                label="Text"
                                v-model="ingredient.value">
                      </fg-input>
                    </div>
                    <div class="col-5">
                      <fg-input type="number"
                                label="Order"
                                v-model="ingredient.sort_order">
                      </fg-input>
                    </div>
                    <div class="col-2 align-self-center mt-3">
                      <l-button
                        @click="removeIngredient(ingredientIndex)"
                        type="danger" size="sm">
                      <span class="btn-label">
                         <i class="fa fa-trash"></i>
                      </span>
                      </l-button>
                    </div>
                  </div>
                </template>
              </div>
              <div class="col-12">
                <l-button
                  class="mt-2"
                  type="success"
                  @click="addNewIngredient()"
                  size="sm">
                       <span class="btn-label">
                         add new ingredient
                         <i class="fa fa-plus"></i>
                         </span>
                </l-button>
              </div>


            </div>
            <div class="row mt-4">
              <h4 class="card-title">Methods</h4>
              <div class="col-12">
                  <template v-for="(method,methodIndex) in formData.methods">
                    <div class="row">
                      <div class="col-4">
                        <fg-input type="text"
                                  label="Text"
                                  v-model="method.title">
                        </fg-input>
                      </div>
                      <div class="col-5">
                        <fg-input type="text"
                                  label="Text"
                                  v-model="method.value">
                        </fg-input>
                      </div>
                      <div class="col-2">
                        <fg-input type="number"
                                  label="Order"
                                  v-model="method.sort_order">
                        </fg-input>
                      </div>
                      <div class="col-1 align-self-center mt-3">
                        <l-button
                          @click="removeMethod(methodIndex)"
                          type="danger" size="sm">
                      <span class="btn-label">
                         <i class="fa fa-trash"></i>
                      </span>
                        </l-button>
                      </div>
                    </div>

                  </template>
              </div>
              <div class="col-12">
                <l-button
                  class="mt-2"
                  type="success"
                  @click="addNewMethod()"
                  size="sm">
                       <span class="btn-label">
                         add new method
                         <i class="fa fa-plus"></i>
                         </span>
                </l-button>
              </div>


            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>

            <l-button @click="$router.push('/recipes/list')" type="danger" wide>Cancel</l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import {FormGroupSelect, ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {
    FgSelect,
    LSwitch,
    FormGroupSelect,
    ImageUploader,
    'editor': Editor
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey:process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        name: "",
        description: "",
        url: "",
        meta_title: "",
        meta_description: "",
        is_active: false,
        is_featured: false,
        sort_order: null,
        image: null,
        methods: [],
        ingredients: [],
      },

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.recipeForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Recipe";
    } else {
      this.editMode = false;
      this.formTitle = "Add Recipe";
    }
    this.prepareBuilders();
  },


  methods: {

    async getRecipe() {
      if (!this.editMode) {
        return;
      }
      try {
        let response = await this.axios.get("recipes/get/" + this.id);
        this.formData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Recipe Not Found",
            timeout: 2000,
            type: 'danger'
          });
        } else {
          console.error(error);
        }
      } finally {
      }
    },

    async prepareBuilders() {
      await this.getRecipe();
      this.loader.hide();
    },
    async submit() {
      if(!this.formData.image){
        this.$notify({
          message: "You Must Upload Image",
          timeout: 2000,
          type: 'danger'
        });
        return;
      }
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("recipes/update/" + this.id, this.formData);
        successMessage = "Recipe Updated Successfully";
      } else {
        request = this.axios.post("recipes/create", this.formData);
        successMessage = "Recipe Added Successfully";
      }

      try {
        await request;
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/recipes/list");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      } finally {
        this.submitting = false;
      }
    },

    generateMetaTitleURL() {

      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
      // meta title
      this.formData.meta_title = this.formData.name;
    },
    addNewIngredient() {
      let order = this.formData.ingredients.length + 1;
      this.formData.ingredients.push({"id": null, value: "", sort_order: order});
    },
    removeIngredient(index) {
      this.formData.ingredients.splice(index, 1);
    },
    addNewMethod() {
      let order = this.formData.methods.length + 1;
      this.formData.methods.push({"id": null,title : "" , value: "", sort_order: order});
    },
    removeMethod(index) {
      this.formData.methods.splice(index, 1);
    }

  }
}
</script>

<style>
</style>
